.selectFilter__column {
  padding: 16px !important;
}

.selectFilter__select {
  width: 100%;
  color: var(--textColor--dark);
}

.selectFilter__buttonRow {
  width: 100%;
}

.selectFilter__button {
  font-size: 100% !important;
  padding: 4px 26px !important;
  margin: var(--gutter) !important;
}

.selectFilter__clearButton {
  background-color: var(--textColor) !important;
}

.selectFilter__clearButton:hover {
  background-color: #606a74 !important;
}

/*# sourceMappingURL=wFKSPDRD.scss.map */