.headerContent__container {
  flex: 1;
  display: flex;
}

.headerContent__content {
  margin-left: auto;
  display: inline-block;
  display: flex;
}

.headerContent__site {
  cursor: pointer;
  font-weight: 500;
  font-size: 1.5rem;
  display: flex;
}

.headerContent__arrow {
  font-size: 1.5rem;
  white-space: nowrap;
  margin: 0 0 2px;
  font-weight: 500;
  align-self: center;
}
