.operationButton {
  color: #0000008a;
  padding: 0.2em;
  cursor: pointer;
}

.operationButton__container {
  display: flex;

  svg:not(:last-child) {
    margin-right: 10px;
  }
}

/*# sourceMappingURL=cYhLZvgY.scss.map */