.historyTab__table {
  margin-top: var(--gutter);
  max-height: 500px;
  overflow-y: scroll;
}

.historyTab__head {
  font-weight: 500;
  font-size: 14px;
  border-bottom: 2px solid var(--primaryColor--light);

  & .column {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & .column:not(:last-child) {
    border-right: 2px solid var(--primaryColor--light);
  }
}

.historyTab__body {
  & .column {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & .column:not(:last-child) {
    border-right: 2px solid var(--primaryColor--light);
  }
}
