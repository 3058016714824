.importDrillDown__centerWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 150px;
}

.importDrillDown__centerContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
}

.importDrillDown__card__container {
  padding: 50px;
}

.importDrillDown__card__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.importDrillDown__card__goodIcon {
  color: var(--successColor--dark);
  font-size: 60px !important;
  margin-bottom: calc(2 * var(--gutter));
}

.importDrillDown__card__badIcon {
  color: var(--errorColor--dark);
  font-size: 60px !important;
  margin-bottom: calc(2 * var(--gutter));
}

.importDrillDown__deleteButton {
  margin-top: calc(2 * var(--gutter)) !important;
}

.importDrillDown__statusesCheckbox {
  margin-right: var(--gutter) !important;
}

.importDrillDown__statusesContainer {
  margin-left: auto;
  color: var(--textColor--dark);
}
