.fileDrop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  width: 100%;
  color: var(--textColor--dark);
  cursor: pointer;
  user-select: none;
  background-color: #dcdcdc;
  border: 2px dashed #8a8a8a;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &.drag {
    background-color: #c6dbe2 !important;
    border: 2px dashed #92afc0 !important;
  }

  &.success {
    background-color: #c6e2c7;
    border: 2px dashed #92c093;
  }

  &.error {
    background-color: #e2c6c6;
    border: 2px dashed #df9e9e;
  }
}

.fileDrop__container {
  text-align: center;
  font-size: 20px;
  padding: 40px 0;
}

.fileDrop__text {
  font-weight: 500;
}

.fileDrop__fileName {
  font-size: 18px;
}

/*# sourceMappingURL=dpArqJYz.scss.map */