.operationsToolbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: none;

  &.operationsToolbar--statusOffset {
    left: 5px;
    border-left: 1px solid white;
  }
}

.operationsToolbar__wrapper {
  position: relative;

  &:hover .operationsToolbar {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}

.operationsToolbar__button {
  cursor: pointer;
  background-color: #272b2c;
  border-width: 1px;
  border-color: #0f1111;
  height: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  outline: none;
  border: 0;

  svg {
    color: white !important;
    font-weight: bold;
    font-size: 2em !important;
  }
}

.operationsToolbar__button--green {
  &:hover {
    background-color: var(--successColor);
    border-color: var(--successColor);
  }

  &:focus {
    background-color: var(--successColor--dark);
    border-color: var(--successColor--dark);
  }
}

.operationsToolbar__button--yellow {
  &:hover {
    background-color: var(--warningColor);
    border-color: var(--warningColor);
  }

  &:focus {
    background-color: var(--warningColor--dark);
    border-color: var(--warningColor--dark);
  }
}

.operationsToolbar__button--red {
  &:hover {
    background-color: var(--errorColor);
    border-color: var(--errorColor);
  }

  &:focus {
    background-color: var(--errorColor--dark);
    border-color: var(--errorColor--dark);
  }
}
