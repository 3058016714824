.json__noCollapsibleRoot {
  .icon-container {
    display: none !important;
  }
}

.json__disabled {
  background: #ebebeb;
}

.react-json-view {
  overflow: auto;
}

/*# sourceMappingURL=dwtFbjUt.scss.map */