.accordion {
  border: 2px solid var(--primaryColor--light);
}

.accordionItem__button {
  outline: none;
  cursor: pointer;
}

.accordionItem__heading {
  border-radius: 0 !important;
  padding: 10px 15px;
  border-bottom: 2px solid var(--primaryColor--light);
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
}

.accordionItem__panel {
  background: white;
  border-bottom: 2px solid var(--primaryColor--light);
  padding: 10px 15px;
}

.accordionItem__heading__left {
  display: flex;
  flex: 1;
  min-width: 0;
}

.accordionItem__heading__right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.accordionItem__arrow {
  margin-left: calc(2 * var(--gutter));
  transition: transform 0.5s ease;
  cursor: pointer;
}

.accordionItem__arrow--open {
  transform: rotate(-180deg);
}

.accordionItem__arrow--closed {
  transform: rotate(0deg);
}

/*# sourceMappingURL=cseJrPyM.scss.map */