.presetDateRangePicker__panel {
  padding: 0 22px 11px 22px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.presetDateRangePicker__select {
  width: 50%;
  margin-top: 0px;
  margin-bottom: 4px;
}

/*# sourceMappingURL=fENuoVSh.scss.map */