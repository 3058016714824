.signInPanel__container {
  width: 100%;
  height: 100%;
  display: flex;
}

.signInPanel__logo {
  user-select: none;
  max-width: 93%;
  max-height: 150px;
}

.signInPanel {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  box-shadow: -6px 4px 15px 8px rgba(0, 0, 0, 0.15);
  width: 800px;
  max-width: 95%;
  height: 400px;
}

.signInPanel__section {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signInPanel__right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to right, var(--primaryColor) 0%, var(--secondaryColor) 120%);
}

/*# sourceMappingURL=fCPepksQ.scss.map */