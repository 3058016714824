.statBox__icon {
  margin-left: 1rem;
  margin-right: 1rem;
}

.statBox__value {
  color: var(--textColor--dark);
  font-size: 1.7rem;
  font-weight: bold;
}

.statBox__name {
  font-size: 1.2rem;
}

.statBox__content {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

/*# sourceMappingURL=eKorRxGv.scss.map */