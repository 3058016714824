.dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dashboard__rightSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.dashboard__content {
  flex: 1;
  padding-top: calc(2 * var(--gutter));
  padding-bottom: calc(2 * var(--gutter));
  overflow: auto;
}

/*# sourceMappingURL=bqVRTdmT.scss.map */