.notesTab__date {
  margin-left: auto;
  font-weight: 500;
  white-space: nowrap;
}

.notesTab__headingText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: var(--gutter);
}

.notesTab__heading {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #ffeded;
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
  min-width: 0;
}

.notesTab__body {
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
  font-size: 14px;
  white-space: pre-line;
  overflow: hidden;
  word-wrap: break-word;
}

.notesTab__accordionContainer {
  max-height: 300px;
  overflow-y: scroll;
}

.notesTab__numNotes {
  font-size: 14px;
}

.notesTab__addText {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
}

.notesTab__textArea {
  margin-top: 0;
}

.notesTab__bottom {
  margin-top: auto;
}
