.link__status {
  font-weight: bold;
}

.link__status--success {
  color: var(--successColor--dark);
}

.link__status--failed {
  color: var(--errorColor--dark);
}

.link__status--unsent {
  color: var(--warningColor--dark);
}

.link__status--inactive {
  color: var(--textColor);
}

.checkbox--good {
  svg {
    color: var(--successColor--dark);
  }
}

.checkbox--bad {
  svg {
    color: var(--errorColor--dark);
  }
}
