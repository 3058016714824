.noTableScrollBar {
  & > div:first-child {
    & > div:nth-child(3) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

/*# sourceMappingURL=dHDtCzZn.scss.map */