.radioFilter__column {
  padding: 16px !important;
}
.radioFilter__checkboxList {
  width: 100%;
  color: var(--textColor--dark);
}
.radioFilter__buttonRow {
  width: 100%;
}
.radioFilter__radio {
  padding: 4px !important;
}
.radioFilter__button {
  font-size: 100% !important;
  padding: 4px 26px !important;
  margin: var(--gutter) !important;
}
.radioFilter__clearButton {
  background-color: var(--textColor) !important;
}
.radioFilter__clearButton:hover {
  background-color: #606a74 !important;
}

/*# sourceMappingURL=dJqqaHXa.scss.map */