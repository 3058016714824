.rowStatusBar {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
}

.rowStatusBar--green {
  background-color: var(--successColor);
}

.rowStatusBar--blue {
  background-color: var(--extraColor2);
}

.rowStatusBar--red {
  background-color: var(--errorColor);
}

.rowStatusBar--yellow {
  background-color: var(--warningColor);
}

.rowStatusBar--gray {
  background-color: rgba(0, 0, 0, 0.54);
}
