.numberFilter__column {
  padding: 16px !important;
}
.numberFilter__select {
  width: 100%;
  color: var(--textColor--dark);
}
.numberFilter__buttonRow {
  width: 100%;
}
.numberFilter__input {
  width: 100%;
}
.numberFilter__button {
  font-size: 100% !important;
  padding: 4px 26px !important;
  margin: var(--gutter) !important;
}
.numberFilter__clearButton {
  background-color: var(--textColor) !important;
}
.numberFilter__clearButton:hover {
  background-color: #606a74 !important;
}

/*# sourceMappingURL=cccIpSDv.scss.map */