.pagination__container {
  padding: calc(4 * var(--gutter));
}

.pagination__link {
  color: #4285f4;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  margin: 0px var(--gutter);
}

.pagination__link-selected {
  color: black;
  text-decoration: none;
  cursor: default;
}

.pagination__link-prev {
  margin-right: calc(2 * var(--gutter));
}

.pagination__link-next {
  margin-left: calc(2 * var(--gutter));
}
