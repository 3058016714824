.importModal__tooltip {
  width: 300px;
  max-width: 100%;
}

.importModal__infoContainer {
  margin-bottom: 10px;
  align-items: center;
}

.importModal__inline {
  display: inline-block;
  padding-right: 5px;
  margin-bottom: 0;
}

.importModal__infoIcon {
  align-self: center;
  color: var(--textColor);
}

.importModal__toggle {
  margin: 0;
}
