.wordFilter__column {
  padding: 16px !important;
}

.wordFilter__select {
  width: 100%;
  color: var(--textColor--dark);
}

.wordFilter__buttonRow {
  width: 100%;
}

.wordFilter__input {
  width: 100%;
}

.wordFilter__button {
  font-size: 100% !important;
  padding: 4px 26px !important;
  margin: var(--gutter) !important;
}

.wordFilter__clearButton {
  background-color: var(--textColor) !important;
}

.wordFilter__clearButton:hover {
  background-color: #606a74 !important;
}

/*# sourceMappingURL=fOfOTDAY.scss.map */