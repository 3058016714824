.searchBox {
  display: inline-block;
}

.searchBox__input {
  padding: 10px;
  border: 2px solid var(--primaryColor--light);
  box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1);
  outline-offset: 0;
  outline-color: var(--secondaryColor);
  background-color: #fff;
  padding-right: 35px;
}

.searchBox__button {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  height: 100%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  outline: none;
  cursor: pointer;
}

.searchBox__icon {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
  background-color: transparent;
  color: inherit;
  border: 0 none;
  height: 36px;
  width: 30px;
}

.searchBox__group {
  background-color: transparent;
  position: relative;
}

/*# sourceMappingURL=cMpGWmnr.scss.map */