.search {
  flex: 1;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 15px 75px 0px 75px !important;
  overflow-y: scroll;
  height: 65vh;
  margin-top: var(--gutter) !important;
}

.search__searchBarRow {
  margin: var(--gutter) 0px !important;
}

.search__searchBarContainer {
  width: 50%;
}

.search__searchBarInput {
  width: 100%;
}
