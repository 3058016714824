.confirmationModal {
  & > div:nth-last-child(2) {
    & > div:first-of-type {
      padding: 50px;
      width: 500px;
      max-width: 98%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.confirmationModal__header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.confirmationModal__headerText {
  font-weight: bold;
}

.confirmationModal__exit {
  margin-left: auto;
  font-size: 28px !important;
  cursor: pointer;
}

.confirmationModal__yesButton {
  background-color: var(--successColor) !important;

  &:hover {
    background-color: var(--successColor) !important;
    filter: brightness(140%);
  }
}

.confirmationModal__noButton {
  background-color: var(--errorColor) !important;

  &:hover {
    background-color: var(--errorColor) !important;
    filter: brightness(140%);
  }
}

/*# sourceMappingURL=fynjumpy.scss.map */