.table {
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  position: relative;
  & > div:first-child {
    & > div:nth-child(3) {
      min-height: 561px;
      max-height: 561px;
      position: relative;
    }
  }
  & tr svg {
    color: #0000008a;
  }

  .th {
    user-select: none;
  }
}

.table-hidden {
  & > div:first-child {
    & > div:nth-child(3) {
      visibility: hidden !important;
    }
  }
}

.datatables-noprint {
  user-select: none;
}

.noScrollBar {
  & > div:first-child {
    & > div:nth-child(3) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
/*# sourceMappingURL=brijduuF.scss.map */