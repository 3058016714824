.radioList {
  margin: 5px;
}

.radioList__radioGroup {
  margin: 2px 0px;
}

.radioButton {
  &:checked {
    color: lightblue;
  }
}

/*# sourceMappingURL=errpDGlM.scss.map */