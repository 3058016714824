.simpleModal {
  & > div:nth-last-child(2) {
    &:before {
      background: black !important;
    }
  }
}

.table {
  margin-top: var(--gutter);
}

.tabMargin {
  margin: calc(1 * var(--gutter)) 0;
}

.table {
  & > div:first-child {
    & > div:nth-child(3) {
      max-height: 640px;
    }
  }
}

.flex {
  display: flex;
}

.flex3 {
  flex: 3;
}

.CalendarDay__selected_span {
  &:not(.CalendarDay__selected) {
    background: var(--linkColor);
  }
}

.CalendarDay__hovered_span {
  background: var(--linkColor) !important;
}

.clearFiltersButton {
  background-color: var(--errorColor) !important;
  font-size: 10px !important;
  padding: 8px 20px !important;
}

.breadcrumbs {
  svg {
    font-size: 1.2rem !important;
  }
}

.breadcrumbs__wrapper {
  min-height: 34px;
  display: flex;
  align-items: center;
}

.breadcrumbs__error {
  color: var(--errorColor);
  font-style: italic;
  font-weight: 500;
}

.ellipsis {
  overflow: hidden;
  text-overflow: 'ellipsis';
  white-space: 'nowrap';
}

.tableLink {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;

  &:hover {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: underline;
  }
}

.MuiHoverCopy {
  &:hover {
    color: #3f51b5;
  }
}

.inlineColumn {
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.label__required {
  display: none;
}

hr {
  opacity: 0.5;
  height: 1px;
  width: 100%;
  background-color: var(--primaryColor--light);
}

.dataModal__body {
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.dataModal__tab {
  padding-left: 20px;
  padding-right: 20px;

  &.active {
    border-bottom-color: var(--primaryColor);
  }
}
