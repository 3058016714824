.searchResult__resultWrapper {
  width: 600px;
  margin-bottom: 26px;
  overflow: visible;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  font-style: normal;
}

.searchResult__loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchResult__headline {
  color: #0000cc;
  font-size: 18px;
  line-height: 1.33;
}

.searchResult__headlineWrapper {
  position: relative;
}

.searchResult__headline:hover {
  color: #0000cc;
  text-decoration: underline;
}

.searchResult__description {
  color: black;
  font-size: small;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  line-height: 1.54;
}

.searchResult__description_cell {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}

.searchResult__displayUrl {
  color: #006621;
  padding-top: 1px;
  font-size: 14px;
  word-wrap: wrap;
  line-height: 1.43;
}

.searchResult__actionButton {
  margin: var(--gutter) !important;
}

.searchResult__actionButton--thumbDown {
  background-color: var(--errorColor) !important;
}

.searchResult__actionButton--thumbUp {
  background-color: var(--successColor) !important;
}

.searchResult__actionButtonRow {
  margin-left: 15px !important;
}

.searchResult__statusBox-green {
  background-color: green;
  position: absolute;
  width: 20px;
  height: 20px;
  left: -22px;
  border-radius: 20px;
  display: inline-block;
  text-align: center;
  cursor: default;
  color: green;
}

.searchResult__statusBox-blue {
  background-color: #009fff;
  position: absolute;
  width: 20px;
  height: 20px;
  left: -22px;
  border-radius: 20px;
  display: inline-block;
  text-align: center;
  cursor: default;
  color: #009fff;
}

.searchResult__statusBox-gray {
  background-color: #009fff;
  position: absolute;
  width: 20px;
  height: 20px;
  left: -22px;
  border-radius: 20px;
  display: inline-block;
  text-align: center;
  cursor: default;
  color: #009fff;
}
