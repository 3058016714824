.breadcrumbs {
  display: flex;
  align-items: center;
}

.breadcrumbs__link {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    color: inherit;
    text-decoration: underline !important;
  }
}

.breadcrumbs__entry {
  display: inline-block;
}

.breadcrumb__entry__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  color: #0000008a;
}

.breadcrumbs__currentPage {
  font-weight: 500;
}

.breadcrumbs__icon {
  margin-right: 10px;
  margin-top: -3px;
}

.breadcrumbs__separator {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.5rem;
}

/*# sourceMappingURL=dkCCJfkn.scss.map */