.simpleModal {
  overflow-y: auto;

  & > div:first-of-type {
    opacity: 0 !important;
    z-index: -1;
    pointer-events: none;
  }

  & > div:nth-last-child(2) {
    &:before {
      pointer-events: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to right,
        var(--primaryColor) 0%,
        var(--secondaryColor) 100%
      ) !important;
      transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
      opacity: 0.85;
      z-index: 0;
      display: block;
      content: '';
    }

    & > div:first-of-type {
      overflow-y: visible;
      max-height: none;
      box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.2) !important;
      z-index: 2;
    }
  }
}

/*# sourceMappingURL=blnoAdtD.scss.map */