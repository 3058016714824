.linkModal__subNote {
  margin-top: -10px;
  font-size: 14px;
  font-style: italic;
}

.linkModal__input--success {
  border-color: var(--successColor);

  &:focus {
    outline-color: var(--successColor--dark);
  }
}

.linkModal__input--error {
  border-color: var(--errorColor);

  &:focus {
    outline-color: var(--errorColor--dark);
  }
}

.linkModal__contactButton {
  border-radius: 5px !important;
  padding: 5px 6px !important;
  min-width: 10px !important;

  &:disabled {
    opacity: 0.7 !important;
  }
}

.linkModal__contactButton--add {
  margin-bottom: 5px !important;
  background-color: var(--successColor) !important;

  &:hover {
    background-color: var(--successColor--dark) !important;
  }
}

.linkModal__contactButton--remove {
  background-color: var(--errorColor) !important;

  &:hover {
    background-color: var(--errorColor--dark) !important;
  }
}

.linkModal__contactIcon {
  font-size: 1.4rem !important;
}

.linkModal__headerButton {
  background-color: var(--successColor) !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;

  &:hover {
    background-color: var(--successColor--dark) !important;
  }

  &:disabled {
    background-color: var(--successColor--light) !important;
  }

  &:not(:first-child) {
    margin-left: 10px;
    padding: 8px 10px !important;
  }
}
