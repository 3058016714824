.imports__success {
  color: var(--successColor--dark);
}

.imports__importing {
  color: var(--warningColor--dark);
}

.imports__pending {
  color: var(--warningColor--dark);
}

.imports__error {
  color: var(--errorColor--dark);
}

.imports__assignedIcon {
  position: absolute;
  margin-left: 16px;
  margin-top: -11px;
}
